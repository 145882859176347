import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import modalActions from "actions/modals";
import { selectCurrentModal } from "selectors/modals";

import useActionCreators from "hooks/useActionCreators";
import { useLocalStorage } from "usehooks-ts";
import useLoggedInUser from "hooks/useLoggedInUser";
import useQuery from "hooks/useQuery";
import useReduxState from "hooks/useReduxState";

function OnboardingTrigger() {
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });
  const user = useLoggedInUser();
  const history = useHistory();
  const location = useLocation();

  const isOnHomepage =
    location.pathname === "/" || location.pathname === "/myfeed";
  const [preventOnboarding] = useLocalStorage(
    "podchaser:prevent_onboarding_trigger",
    null
  );

  const [query, setQuery] = useQuery();

  const hasTriggered = useRef(false);

  const currentModal = useReduxState((state) => selectCurrentModal(state), []);

  useEffect(() => {
    if (isOnHomepage && !currentModal) {
      if (user && query.onboarding) {
        const newQuery = Object.entries(query).reduce((qu, [key, value]) => {
          if (key !== "onboarding") {
            qu[key] = value;
          }

          return qu;
        }, {});

        setQuery(newQuery);
        showModal("onboarding");
        hasTriggered.current = true;
      }
    }
  }, [
    history,
    location,
    query,
    showModal,
    user,
    isOnHomepage,
    preventOnboarding,
    currentModal,
    setQuery,
  ]);

  return null;
}

export default OnboardingTrigger;
